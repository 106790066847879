import { defineComponent } from "vue";

import Hero from "@/components/hero/index.vue";
import SummitLink from "@/components/summit-link/index.vue";
import heroBg from "../../assets/images/about-us-bg.png";

export default defineComponent({
  name: "Business-Related Regulations",
  components: { SummitLink, Hero },
  setup() {
    const heroSlide = [
      {
        title: "Summit Report",
        image: heroBg,
      },
    ];

    return {
      heroSlide,
    };
  },
  data() {
    return {
      
      projects: [
        { 
          project_name: 'Auta Balefi Waste Management Facility', 
          sector: 'Environment', 
          lga:'Karu', 
          phase:'Implementation', 
          value:'21,000,000.00', 
          s_authority:'Nasarawa State Waste Management Bureau', 
          climate_report:'0', 
          link:'', 
        },
        { 
          project_name: 'Auta Balefi Waste Management Facility', 
          sector: 'Environment', 
          lga:'Karu', 
          phase:'Implementation', 
          value:'21,000,000.00', 
          s_authority:'Nasarawa State Waste Management Bureau', 
          climate_report:'1', 
          link:'https://api.nasida.na.gov.ng/downloads/PROCEDURE-FOR-CONTRACTORS-REGISTRATION', 
        },
        { 
          project_name: '', 
          sector: '', 
          lga:'', 
          phase:'', 
          value:'', 
          s_authority:'', 
          climate_report:'', 
          link:'', 
        },
      ],
    }
  }, 

  methods: {
    goto(url) {
      window.open(url);
    },
  },


});
