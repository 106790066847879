<template>
  <div id="our-governance w-full relative">
    <div class="governance_body mt-10">
      <div class="mb-4">
        <div class="sub_headings text-4xl">NASARAWA STATE PPP PIPELINE</div>
        <!-- <div class="mt-6 text-xl">
          View the reports
        </div> -->
      </div>

      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-12 lg:-mx-12">
          <div class="inline-block min-w-full py-2">
            <div class="overflow-hidden">
              <table class="min-w-full text-left text-sm font-light">
                <thead class="border-b font-medium dark:border-neutral-500" style="background-color: #1B914D; color: white; weight:3;">
                  <tr>
                    <th scope="col" class="px-6 py-4">S/N</th>
                    <th scope="col" class="px-6 py-4">PROJECTS NAME</th>
                    <th scope="col" class="px-6 py-4">SECTOR</th>
                    <th scope="col" class="px-6 py-4">LOCAL GOVERNMENT</th>
                    <th scope="col" class="px-6 py-4">PHASE</th>
                    <th scope="col" class="px-6 py-4">VALUE (USD)</th>
                    <th scope="col" class="px-6 py-4">SUPERVISING AUTHORITY</th>
                    <th scope="col" class="px-6 py-4">PPP AND CLIMATE SCREENING REPORT</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- <tr class="border-b dark:border-neutral-500" v-for="p in projects" :key="p.project_name">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      1
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      {{ p.project_name }}
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      {{p.sector}}
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      {{p.lga}}
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      {{p.phase}}
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      {{p.value}}
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      {{p.s_authority}}
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      <span v-if="p.climate_report === '1'">
                        <DownloadLink :text="'click here'" :url="p.link" />
                      </span>
                      <span v-else-if="p.climate_report === '0'">
                        NO
                      </span>
                    </td>
                  </tr> -->

                  <!--  -->
                  <tr class="border-b dark:border-neutral-500" style="background: #b2ceb1;">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      1
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Auta Balefi Waste Management Facility
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Environment
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Karu
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Implementation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      21,000,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa State Waste Management Bureau
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      YES ( <a target="_blank" href="https://api.nasida.na.gov.ng/downloads/Screening-Assessment-Report-of-the-Auta-Balefi-Waste-Management-Facility-Karu-v2">Click to download</a>)
                    </td>
                  </tr>
                  <!--  -->
                  <tr class="border-b dark:border-neutral-500">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      2
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa Meter Manufacturing Plant
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Energy
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Lafia
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Implementation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      5,500,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa Electricity Power Agency
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      YES (<a href="https://api.nasida.na.gov.ng/downloads/Screening-Assessment-Report-of-the-Nasarawa-Meter-Manufacturing-Project-v2">Click to download</a>)
                    </td>
                  </tr>
                  <!--  -->
                  <tr class="border-b dark:border-neutral-500" style="background: #b2ceb1;">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      3
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Gudi Industrial Hub
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Agriculture
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Akwanga
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Preparation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      154,000,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa Investment and Development Agency
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      YES (<a target="_blank" href="https://api.nasida.na.gov.ng/downloads/Screening-Assessment-Report-of-the-Gudi-Industrial-Hub-Project-v2">Click to download</a>)
                    </td>
                  </tr>
                  <!--  -->
                  <tr class="border-b dark:border-neutral-500">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      4
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa State University Keffi On- Campus Student Accommodation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Education
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Keffi
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Preparation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      19,100,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa State University Keffi
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      YES (<a target="_blank" href="https://api.nasida.na.gov.ng/downloads/Screening-Assessment-Report-of-the-NSUK-Student-Accommodation-Project-v2">Click to download</a>)
                    </td>
                  </tr>
                  <!--  -->
                  <tr class="border-b dark:border-neutral-500" style="background: #b2ceb1;">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      5
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa Technology Village
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Urban Development
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Karu
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Implementation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      20,745,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa Investment and Development Agency
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      NO
                    </td>
                  </tr>
                  <!--  -->
                  <tr class="border-b dark:border-neutral-500">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      6
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Karu Retail and Recreational Mall
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Urban Development
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Karu
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Implementation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      4,461,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa Investment and Development Agency
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      NO
                    </td>
                  </tr>
                  <!--  -->
                  <tr class="border-b dark:border-neutral-500" style="background: #b2ceb1;">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      7
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Azuba Cassava Processing Park
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Agriculture
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Lafia
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Implementation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      6,692,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa State Ministry of Agriculture and Water Resources
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      NO
                    </td>
                  </tr>
                  <!--  -->
                  <tr class="border-b dark:border-neutral-500">
                    <td class="whitespace-nowrap border px-6 py-4 font-medium dark:border-neutral-500">
                      8
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Peninsula Housing Estate
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Urban Development
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Karu
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Implementation
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      8,759,000.00
                    </td>
                    <td class="whitespace-nowrap border-r px-6 py-4 dark:border-neutral-500">
                      Nasarawa Investment and Development Agency
                    </td>
                    <td class="whitespace-nowrap border px-6 py-4">
                      NO
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>





      <!-- <SummitLink
        v-for="f in filesrcs"
        @click="goto(f.src)"
        :key="f.title"
        :name="f.title"
        :link="f.src"
        :title="f.time"
      ></SummitLink> -->
    </div>
  </div>
</template>

<script src="./ppp.page.js"></script>
<style src="./ppp.page.css" scoped lang="css"></style>
